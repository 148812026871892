import * as React from "react"
import { graphql } from "gatsby"
import {
  PreStyledComponents,
  IconArrowRight,
  CardLink,
  Variables
} from "@life-without-barriers/react-components"
import { Site, SiteMetaData } from "@life-without-barriers/gatsby-common"

import {
  BreadCrumbLink,
  BreadCrumbAnchor
} from "../../components/carer-guide/localStyledComponents"
import { excerpts } from "../../lib//trimMarkdownHtml"
import Layout from "../../components/carer-guide/Layout"
import WrappedHelmet from "../../components/carer-guide/WrappedHelmet"

const { GridWrapper, Container } = PreStyledComponents
const { youthPinkMedium } = Variables

interface BreadCrumbProps {
  current: string
  siteMetadata: SiteMetaData
}

const trimTileCopy = (str: string, max = 150) => {
  if (str.length > max) {
    const sub = str.substring(0, max)
    const lastIndex = sub.lastIndexOf(" ")
    return `${sub.substr(0, lastIndex)}...`
  }
  return str
}

const BreadCrumb = ({ siteMetadata, current }: BreadCrumbProps) => (
  <div className="inline-flex flex-wrap mt4 mt6-ns ph3 pv3 br2 bg-lwb-theme-xxx-light t-category-breadcrumbs">
    <BreadCrumbAnchor
      className="black normal nowrap"
      to={`${siteMetadata.siteUrl}/foster-care/`}
      title="Foster care"
    >
      Foster care
    </BreadCrumbAnchor>
    <div className="mh2">/</div>
    <BreadCrumbLink
      className="black normal nowrap"
      to="/foster-care/support/carer-guide/"
    >
      Carer guide
    </BreadCrumbLink>
    <div className="mh2">/</div>
    <div>{current}</div>
  </div>
)

interface TopicLink {
  id: string
  title: string
  slug: string
  content: {
    childMarkdownRemark: {
      html: string
    }
  }
}

interface CategoryProps {
  location: { pathname: string }
  data: {
    allContentfulCarerGuideCategory: {
      edges: [
        {
          node: {
            id: string
            slug: string
            categoryTitle: string
            topicLinks: [TopicLink]
          }
        }
      ]
    }
    site: Site
  }
}

const renderTopic = (topicLink: TopicLink, i: number) => (
  <CardLink
    key={i}
    to={`/foster-care/support/carer-guide/topic/${topicLink.slug}`}
    title={topicLink.title}
    isCentered={false}
    showReadMore={true}
  >
    <div className="pa3 pa4-ns flex flex-column h-100">
      <div className="CardLinkContents-body flex flex-column flex-auto z-1">
        <div className="CardLinkContents-top">
          <h2 className="ts-display-4">{topicLink.title}</h2>
          <div className="tl normal mv3 lh-title lh-copy-m copy">
            <div className="lh-copy">
              {trimTileCopy(
                excerpts(topicLink.content.childMarkdownRemark.html, {
                  characters: 150
                })
              )}{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="CardLink-readmore CardLinkContents-readmore z-1 mt2 fw6 flex items-baseline justify-content">
        Read more{" "}
        <IconArrowRight
          height="8"
          width="5"
          color={youthPinkMedium}
          className="ml2"
        />
      </div>
    </div>
  </CardLink>
)

const CategoryPage = (props: CategoryProps) => {
  const { node } = props.data.allContentfulCarerGuideCategory.edges[0]
  const {
    site: { siteMetadata }
  } = props.data
  return (
    <Layout
      siteMetadata={props.data.site.siteMetadata}
      location={props.location}
    >
      <div className="bg-lwb-grey-xxx-light">
        <WrappedHelmet
          title={`${node.categoryTitle} - Carer guide | ${siteMetadata.title}`}
          description={`Support topics for the ${node.categoryTitle} category`}
          siteUrl={siteMetadata.siteUrl}
          path={props.location.pathname}
        />
        <Container>
          <div className="pb4 ph3">
            <BreadCrumb
              current={node.categoryTitle}
              siteMetadata={siteMetadata}
            />
            <h1 className="ts-display-1 fw8 mt4-ns">{node.categoryTitle}</h1>
            <GridWrapper className="flex-ns flex-wrap-ns mt3 mt4-ns">
              {node.topicLinks &&
                node.topicLinks.map((topicLink, i) =>
                  renderTopic(topicLink, i)
                )}
            </GridWrapper>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CategoryQuery($categoryTitle: String) {
    allContentfulCarerGuideCategory(
      filter: { categoryTitle: { eq: $categoryTitle } }
    ) {
      edges {
        node {
          id
          slug
          categoryTitle
          topicLinks {
            id
            title
            slug
            content {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default CategoryPage
